import { StoreForm } from '../forms/useStoreForm';
import { gozokiApiWithAuth } from '../gozokiApi';

// Create a store
export const createStore = async (data: StoreForm) => {
    const response = await gozokiApiWithAuth.post('/stores', data);
    return response;
};

// Destroy a store
export const destroyStore = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/stores/${id}`);
    return response;
};

// Destroy multiple stores
export const destroyStores = async (ids: number[]) => {
    const responses = await Promise.all(ids.map((id) => destroyStore(id)));
    return responses;
};

export const refreshStocks = async () => {
    const response = await gozokiApiWithAuth.post(`/sync/stock`);
    return response;
};

export const refreshBearcod = async () => {
    const response = await gozokiApiWithAuth.post(`/sync/update-bearcod`);
    return response;
};

// Update a store
export const updateStore = async ({ id, data }: UpdateStoreArgs) => {
    const response = await gozokiApiWithAuth.patch(`/stores/${id}`, data);
    return response;
};

export const cleanStoreArticles = async (id: number) => {
    const response = await gozokiApiWithAuth.post<number>(`/stores/${id}/clean-articles`);
    return response;
};

interface UpdateStoreArgs {
    id: number;
    data: Partial<StoreForm>;
}

export const downloadAdminXlsx = async ({
    dateStart,
    dateEnd,
    storeId,
}: {
    dateStart: string;
    dateEnd: string;
    storeId?: number;
}) => {
    const response = await gozokiApiWithAuth.get(
        `/stores/get-audit-xlsx/${dateStart}/${dateEnd}${storeId ? `?storeId=${storeId}` : ''}`,
        {
            responseType: 'blob',
        }
    );
    return response;
};

export const downloadPaymentsCsv = async ({
    dateStart,
    dateEnd,
}: {
    dateStart: string;
    dateEnd: string;
}) => {
    const response = await gozokiApiWithAuth.get(
        `/stores/get-payments-csv/${dateStart}/${dateEnd}`,
        {
            responseType: 'blob',
        }
    );
    return response;
};

export const downloadStockAuditCsv = async (id: number) => {
    const response = await gozokiApiWithAuth.get(`/stores/${id}/get-stock-audit-csv`, {
        responseType: 'blob',
    });
    return response;
};

export const rebootCpiKiosk = async (kioskId: string) => {
    const response = await gozokiApiWithAuth.post(`/stores/reboot-kiosk/${kioskId}`);
    return response;
};

export const downloadTicketsCsv = async ({
    dateStart,
    dateEnd,
}: {
    dateStart: string;
    dateEnd: string;
}) => {
    const response = await gozokiApiWithAuth.get(
        `/stores/get-all-tickets-csv/${dateStart}/${dateEnd}`,
        {
            responseType: 'blob',
        }
    );
    return response;
};

export const mooveArticlesBetweenStores = async ({
    originId,
    destinationId,
    articlesIds,
    allArticles,
}: {
    originId: number;
    destinationId: number;
    articlesIds?: number[];
    allArticles?: boolean;
}) => {
    const response = await gozokiApiWithAuth.post(`/stores/moove-articles`, {
        originId,
        destinationId,
        articlesIds,
        allArticles,
    });
    return response;
};
