import {
    CartsWithReportUrl,
    Lot,
    Product,
    PromoProduct,
    StockData,
    Store,
    UniqueArticle,
} from '@gozoki/api-types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

// Fetch one store
export const fetchStore = async (id: number) => {
    const response = await gozokiApi.get<Store>(`/stores/${id}`);
    return response.data;
};

// Fetch all stores
export const fetchStores = async () => {
    const response = await gozokiApi.get<Store[]>('/stores');
    return response.data;
};

export const fetchBoutiques = async () => {
    const response = await gozokiApi.get<Store[]>('/stores/boutiques');
    return response.data;
};

// Fetch all articles related to a store. Admin only (back office)
export const fetchStoreArticles = async (id: number) => {
    const response = await gozokiApiWithAuth.get<UniqueArticle[]>(`/stores/${id}/unique_articles`);
    return response.data;
};

export const fetchStoreProducts = async (id: number) => {
    const response = await gozokiApi.get<Product[]>(`/stores/${id}/products`);
    return response.data;
};

export const fetchStoreNewProducts = async (id: number, page?: number) => {
    if (page) {
        const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
            `/stores/${id}/new-products/${page}`
        );
        return { data: response.data.products, hasMorePage: response.data.hasMorePage };
    }
    const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
        `/stores/${id}/new-products`
    );
    return { data: response.data.products, hasMorePage: response.data.hasMorePage };
};

export const fetchAntiGaspi = async (id: number, page?: number) => {
    if (page) {
        const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
            `/stores/${id}/antigaspi/${page}`
        );
        return { data: response.data.products, hasMorePage: response.data.hasMorePage };
    }
    const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
        `/stores/${id}/antigaspi`
    );
    return { data: response.data.products, hasMorePage: response.data.hasMorePage };
};

export const fetchStoreCategoryProducts = async (id: number, cat: number, page?: number) => {
    if (page) {
        const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
            `/stores/${id}/category-products/${cat}/${page}`
        );
        return { data: response.data.products, hasMorePage: response.data.hasMorePage };
    }
    const response = await gozokiApi.get<{ products: Product[]; hasMorePage: boolean }>(
        `/stores/${id}/category-products/${cat}`
    );
    return { data: response.data.products, hasMorePage: response.data.hasMorePage };
};

export const fetchPromoProducts = async (id: number, page?: number) => {
    if (page) {
        const response = await gozokiApi.get<{
            promoProducts: PromoProduct[];
            hasMorePage: boolean;
        }>(`/stores/${id}/promo-products/${page}`);
        return { data: response.data.promoProducts, hasMorePage: response.data.hasMorePage };
    }
    const response = await gozokiApi.get<{ promoProducts: PromoProduct[]; hasMorePage: boolean }>(
        `/stores/${id}/promo-products`
    );
    return { data: response.data.promoProducts, hasMorePage: response.data.hasMorePage };
};

/** Fetch stock data for a shop */
export const fetchStoreStock = async (id: number) => {
    const response = await gozokiApiWithAuth.get<StockData>(`/stores/${id}/stock`);
    return response.data;
};
// fetch multiples stores stock data
export const fetchStoresStocks = async (ids: number[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.get<StockData[]>(`/stores/stocks`, { params: data });
    return response.data;
};

export const fetchStoresmMetaDatas = async (ids: number[]) => {
    const data = { ids };
    const response = await gozokiApiWithAuth.get<StockData[]>(`/stores/metadatas`, {
        params: data,
    });
    return response.data;
};

/** Fetch store articles associated with a product */
export const fetchStoreProductArticles = async (storeId: number, productId: number) => {
    const response = await gozokiApiWithAuth.get<UniqueArticle[]>(
        `/stores/${storeId}/product/${productId}`
    );
    return response.data;
};

/** Get all stores data as a CSV string */
export const exportStoresAsCSV = async () => {
    const response = await gozokiApiWithAuth.get<string>('/stores/export');
    return response.data;
};

/** Get a specific store restock data as a CSV string */
export const exportStoresRestockingAsCSV = async (data: {
    type: string;
    selectedStore: number;
}) => {
    const response = await gozokiApiWithAuth.get<string>(
        `/stores/export-restocking?type=${data.type}&selectedStore=${data.selectedStore}`
    );
    return response.data;
};

export const fetchTodayDatas = async (id: number) => {
    const response = await gozokiApiWithAuth.get<{
        todayCA: number;
        todayNbArticles: number;
        articlesSellTodayMap: { id: number; value: number }[];
        articlesSellTodayMapByCa: { id: number; value: number }[];
    }>(`/stores/${id}/today-datas`);
    return response.data;
};

export const fetchStoreCommandNumber = async (id: number) => {
    const response = await gozokiApiWithAuth.get<number>(`/stores/${id}/get-command-number`);
    return response.data;
};

export const fetchStoreCpiKiosks = async (reference: string) => {
    const response = await gozokiApiWithAuth.get<{ id: string; name: string }[]>(
        `/stores/cpi-kiosks/${reference}`
    );
    return response.data;
};

export const fetchPromoData = async (dateStart: string, dateEnd: string, ids: number[]) => {
    const response = await gozokiApiWithAuth.get<
        {
            usedCount: number;
            ca: number;
            usersCount: number;
            usedAmount: number;
            promoCampaignLabel: string;
            addedCount: number;
        }[]
    >(`/stores/get-promos-datas/${dateStart}/${dateEnd}/${ids.join('-')}`);
    return response.data;
};

export const fetchLotsData = async (
    page: number | null,
    pageSize: number | null,
    lot: string | null,
    ean: string | null,
    label: string | null,
    store: number | null,
    specials?: boolean
) => {
    const response = await gozokiApiWithAuth.get<{
        data: Lot[];
        meta: {
            total: number;
            perPage: number;
            currentPage: number;
            lastPage: number;
        };
    }>(
        `/stores/get-lots-datas?${page ? `page=${page}` : ''}${
            pageSize ? `&pageSize=${pageSize}` : ''
        }${lot ? `&lot=${lot}` : ''}${ean ? `&ean=${ean}` : ''}${store ? `&store=${store}` : ''}${
            label ? `&label=${label}` : ''
        }${specials ? `&specials=${!!specials}` : ''}`
    );
    return response.data;
};

export const fetchCartsDatas = async (
    page: number | null,
    pageSize: number | null,
    startDate: string | null,
    endDate: string | null,
    cartStatus: string | null,
    storeId: number
) => {
    const response = await gozokiApiWithAuth.get<{
        data: CartsWithReportUrl[];
        meta: {
            total: number;
            perPage: number;
            currentPage: number;
            lastPage: number;
        };
    }>(
        `/stores/${storeId}/carts-data?${page ? `page=${page}` : ''}${
            pageSize ? `&pageSize=${pageSize}` : ''
        }${startDate ? `&startDate=${startDate.replace('+', '%2B')}` : ''}${
            endDate ? `&endDate=${endDate.replace('+', '%2B')}` : ''
        }${cartStatus ? `&cartStatus=${cartStatus}` : ''}`
    );
    return response.data;
};
